import { Alert, Box, Button, Container, Divider, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dashboardLogo from '../../assets/images/bh.png';
import { getBearerToken, setBearerToken } from '../../utils/storage';
import TermsOfUse from '../common/TermsOfUse';
import {useParams } from 'react-router';

export default function Login() {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const [termOpen, setTermOpen] = useState(false);
    const [email, setEmail] = useState({})
    const [loginErrorMessage, setLoginErrorMessage] = useState(false);
    const params = useParams();
    const queryParameters = new URLSearchParams(location.search)
    
 

    const from = location.state && location.state.from ? location.state.from.pathname || "/" : "/";

    /**
     * Clear authentication on opening login page.
     */
    useEffect(() => {
        localStorage.clear();
       
        console.log('params',queryParameters.get("code"))
       if (params){
        axios.get('/sso', {params: {code: queryParameters.get("code")}}).then(loginResponse => loginResponse.data).then(
            authenticationDetails => {
            if (authenticationDetails && !!authenticationDetails.token) {
                setBearerToken(authenticationDetails.token);
                axios.defaults.headers.common['Authorization'] = getBearerToken();
                axios.get('/user-detail').then(response => response.data).then(userDetails => {
                    console.log('checking', userDetails.termsAccepted, new Date(userDetails.termsAccepted).getFullYear(), new Date().getFullYear())
                    setEmail(userDetails.email);
                    if (userDetails.termsAccepted && (new Date(userDetails.termsAccepted).getFullYear() === new Date().getFullYear())) {
                        navigate(from, { replace: true });
                    } else {
                        setTermOpen(true)
                    }
                });
                setLoginErrorMessage(false);
            } else if (authenticationDetails && authenticationDetails.message) {
                setLoginErrorMessage(authenticationDetails.message);
                enqueueSnackbar(authenticationDetails.message, { variant: 'error' });
            }
        }
        ).catch();
    }
      
    }, []);

    /**
     * Handle Login Form Submit.
     * @param {*} event 
     */
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const formData = new FormData(event.currentTarget);
    //     axios.post('/login', {
    //         username: formData.get("username"),
    //         password: formData.get("password")
    //     }).then(loginResponse => loginResponse.data).then(authenticationDetails => {
    //         if (authenticationDetails && !!authenticationDetails.token) {
    //             setBearerToken(authenticationDetails.token);
    //             axios.defaults.headers.common['Authorization'] = getBearerToken();
    //             axios.get('/user-detail').then(response => response.data).then(userDetails => {
    //                 console.log('checking', userDetails.termsAccepted, new Date(userDetails.termsAccepted).getFullYear(), new Date().getFullYear())
    //                 setEmail(userDetails.email);
    //                 if (userDetails.termsAccepted && (new Date(userDetails.termsAccepted).getFullYear() === new Date().getFullYear())) {
    //                     navigate(from, { replace: true });
    //                 } else {
    //                     setTermOpen(true)
    //                 }
    //             });
    //             setLoginErrorMessage(false);
    //         } else if (authenticationDetails && authenticationDetails.message) {
    //             setLoginErrorMessage(authenticationDetails.message);
    //             enqueueSnackbar(authenticationDetails.message, { variant: 'error' });
    //         }
    //     }).catch(() => enqueueSnackbar('Something went wrong!', { variant: 'error' }));
    // }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        axios.post('/login', {
            username: formData.get("username"),
            password: formData.get("password")
        }).then(loginResponse => loginResponse.data).then(authenticationDetails => {
            if (authenticationDetails && !!authenticationDetails.token) {
                setBearerToken(authenticationDetails.token);
                axios.defaults.headers.common['Authorization'] = getBearerToken();
                axios.get('/user-detail').then(response => response.data).then(userDetails => {
                    console.log('checking', userDetails.termsAccepted, new Date(userDetails.termsAccepted).getFullYear(), new Date().getFullYear())
                    setEmail(userDetails.email);
                    // if (userDetails.termsAccepted && (new Date(userDetails.termsAccepted).getFullYear() === new Date().getFullYear())) {
                    //     navigate(from, { replace: true });
                    // } else {
                    //     setTermOpen(true)
                    // }
                    if(userDetails.termsAccepted ===null){
                        setTermOpen(true)
                    }
                    else{
                        navigate(from, { replace: true });
                    }
                });
                setLoginErrorMessage(false);
            } else if (authenticationDetails && authenticationDetails.message) {
                setLoginErrorMessage(authenticationDetails.message);
                enqueueSnackbar(authenticationDetails.message, { variant: 'error' });
            }
        }).catch(() => enqueueSnackbar('Something went wrong!', { variant: 'error' }));
        
    }


    return (
        <React.Fragment>
            <Container sx={{ minHeight: '100vh', display: 'flex', alignItems: "center" }}>
                <Paper elevation={5} sx={{ p: 3 }}>
                    
                    <Stack direction='row' alignItems="stretch" justifyContent="center" spacing={3} divider={<Divider orientation="vertical" flexItem />}>
                        <Stack component={Grid} item xs={5} sx={{ width: 3 / 5, textAlign: 'center' }} justifyContent="space-around" alignItems="center">
                            <Typography variant='h4'> Dashboard Login Instructions</Typography>
                            <Typography>If you forgot your password or if you are not sure if you have one, please click the Forgot Password button and follow the instructions.</Typography>
                            <Typography>Have a quick question? email <a href="mailto:bluehawk.helpdesk@tredence.com">bluehawk.helpdesk@tredence.com</a></Typography>
                            <Typography>Learn how to upload your transactional data, use this link, <a href="https://calendly.com/blue-hawk-dashboard-project" rel="noreferrer" target="_blank">BLUE HAWK Dashboard Project</a> to schedule a call with Tredence.</Typography>
                            <Typography>Schedule a Dashboard Tour with BLUE HAWK staff to learn best practices for using your Dashboard: <a href="https://calendly.com/jjonesbh/the-dashboard-walk-through" rel="noreferrer" target="_blank">Dashboard Walk Through </a></Typography>
                            <p>Have questions about Data Security?  <a href="mailto:sanjivi.kumar@tredence.com"> Contact senior privacy/security officer</a></p>
                        </Stack>
                        <Stack spacing={2} sx={{ width: 2 / 5 }} component={'form'} onSubmit={handleSubmit}>
                            <Box><img src={dashboardLogo} alt="bh logo" style={{ height: '5rem' }} /></Box>
                            {!!loginErrorMessage ? (
                                <Alert severity="error">{loginErrorMessage}</Alert>
                            ) : (
                                <Alert severity="info">You must log in to view The Dashboard!</Alert>
                            )}
                            <TextField fullWidth variant="outlined" name="username" label="Username" autoComplete="off" required />
                            <TextField fullWidth variant="outlined" name="password" label="Password" type="password" autoComplete="off" required />
                            <Stack direction="row" spacing={2}>
                                <Button type="submit" size='large' variant='contained' color='primary'>Login</Button>
                                <Button component={Link} to={'/forgot-password'} variant='outlined' color='primary'>Forgot Password</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Paper>
            </Container>
            <TermsOfUse open={termOpen} setOpen={setTermOpen} email={email} handleAgree={() => navigate(from, { replace: true })} />
        </React.Fragment>
    );
}
