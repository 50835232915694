import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';


export default function TermsOfUse(props){
    const [acceptTerm,setAcceptTerm]=useState('');
    const [disAgree,setDisAgree]=useState('');

    useEffect(() => {
        
    }, []);


    const handleAccept = () => {
        axios.post('/acceptTerms',{email:props.email}).then((res) => {
            if (res.data) {
                setAcceptTerm(res.data);
                console.log('acceptTerm Data',acceptTerm)
                props.handleAgree()
            } else if (res.data) {
                console.info('Accept Response: ', res.data);
            }
        });
      };
    

    
      const handleDisagree = () => {
        axios.post('/disagreeTerms', { email: props.email }).then((res) => {
            if (res.data) {
                setDisAgree(res.data);
                console.log('acceptTerm Data',disAgree)
            } else if (res.data) {
                console.info('Accept Response: ', res.data);
            }
        });
        props.setOpen(false);
      };
   


    return(
        <React.Fragment>
   <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
        TERMS OF USE
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
                         <h2>
                         END USER LICENSE AGREEMENT
                        </h2>
                        <h3>PLEASE READ THIS END-USER LICENSE AGREEMENT ("EULA") CAREFULLY. BY INSTALLING OR USING THE PLATFORM THAT ACCOMPANIES THIS EULA, YOU AGREE TO THE TERMS HEREIN. IF YOU DO NOT AGREE, YOU SHOULD NOT INSTALL OR USE THIS PLATFORM.</h3>
                        <h3>Terms and conditions</h3>
                        <div>
                        This is an agreement between the <b>“End Users”</b> and <b>Tredence, Inc.,</b> a company incorporated under the laws of Delaware, having its principal place of business at 1900 Camden Ave #66, San Jose CA 95124 (hereinafter referred to as <b>“Company”,</b> which expression shall include <del style={{color:'red'}}>where appropriate</del> its affiliates, successors and assigns<ins style={{color:'red'}}>, subsidiaries</ins>) is THE SOLE OWNER OF THE PLATFORM APPLICATION Rebate.ai <b>(Helps customers to manage and track their Rebate Earnings)</b> - Read carefully before using this web/desktop application. By using this web/desktop application, you agree with these terms and condition.
                        </div>
                        <br></br>
                        <div>
                            <b>1.	</b><b>“Rebate.ai” </b>  (hereinafter referred to as <b>"Product"</b> for the sake of brevity), <b>Product</b> means the Company’s proprietary Platform application more fully described in <b>Annexure 1</b> of this Agreement, which is to be used in accordance with terms of this Agreement and in the manner as set out in the Documentation and shall include all Customizations and Enhancements thereto.

                        </div>
                        <br></br>
                        <div>
                            <b>2.	</b>The Company is engaged in the business of data analytics and has developed Product, a Platform application which helps customers manage and optimize their rebate in a B2B procurement ecosystem.
                        </div>
                        <br></br>
                        <div>
                            <b>3.   </b> 3.	The Company agrees to license Product and the End User agrees to use the same in accordance with the terms set out in this Agreement. Where required following terms shall be construed as following:

                        </div>
                        <br></br>
                        <div>
                            <b>a.   </b> <b>“End User Data”</b> means any information, application, file, text or such other material that is uploaded into the Product only. Company has no access to End User’s any personally identifiable information (PII) data. 
                        </div>
                        <br></br>
                        <div>
                            <b>b.   </b>	<b>“Permitted Systems”</b> means the systems used by the Customer to host the Product.

                        </div>
                        <br></br>
                        <div>
                            <b>c.	</b>	<b>“Intellectual Property Right(s)”</b> means and includes, without limitation, any patents, copyrights, trademarks, trade secrets, service marks (in each case, whether registered or not), registered designs, database right, design right, moral right or any other property rights that grant similar rights as the foregoing, whether registered or not, anywhere in the world <ins style={{color:'red'}}>which also included all pre-existing intellectual property owned by Company;</ins> 
                        </div>
                        <br></br>
                        <div>
                            <b>d.	</b>	<b>Proprietary Information</b> means: (i) the Product and the Documentation and any complete or partial copies thereof (including any modifications or enhancements thereto); (ii) the concepts, techniques, ideas, and know-how embodied and expressed in the Product, including its architecture, structure, sequence, and organization; and (iii) any inventions, drawings, performances, Platform, databases and methodologies in any manner related to the Product and the Documentation; 
                        </div>
                        <br></br>
                        <div>
                            <b>e.	</b>	<b>Documentation</b> means the period specified in attached SOW; 
                        </div>
                        <br></br>
                        <div>
                            <b>f.	</b>	<b>Term</b> means and includes, without limitation, any patents, copyrights, trademarks, trade secrets, service marks (in each case, whether registered or not), registered designs, database right, design right, moral right or any other property rights that grant similar rights as the foregoing, whether registered or not, anywhere in the world which also included all pre-existing intellectual property owned by Company; 
                        </div>
                        <br></br>
                        <div>
                            <b>g.	</b>	<b>Use</b> or <b>Used  </b> means to directly or indirectly activate the processing capabilities of the Product, and/or execute, access, utilize, or display information resulting from such capabilities; and
                        </div>
                        <br></br>
                        <div>
                            <b>h.	</b>	<b>“Intellectual Property Right(s)”</b> means and includes, without limitation, any patents, copyrights, trademarks, trade secrets, service marks (in each case, whether registered or not), registered designs, database right, design right, moral right or any other property rights that grant similar rights as the foregoing, whether registered or not, anywhere in the world which also included all pre-existing intellectual property owned by Company; 
                        </div>
                        <br></br>
                        <div>
                            <b>i.	</b>	<b>	End User </b>means an individual who Use the Product or who has been supplied user identifications and passwords (in compliance with the requirements specified in this regard by the Company). Tredence do not limit usage of license to certain number of Users only, provided they <del style={{color:'red'}}>are allowed to</del><ins style={{color:'red'}}>shall</ins> use Product as per this Agreement.
                        </div>
                        <br></br>
                       
                        <div>
                            <b>  4.	</b> Company hereby grants the End User a revocable, non-exclusive, non-transferable, non-sublicensable, worldwide, limited license to install the Product on the Permitted Systems and Use the Product and the corresponding Documentation, for the Term, subject to the terms of this Agreement and attached SOW.
                        </div>
                        <br></br>
                        <div>
                            <b>  5.	</b> End User shall not do any modifications to the Product and its features. 
                        </div>
                        <br></br>
                        <div>
                            <b>  6.	</b> Nothing set forth in this Agreement shall restrict the Company from collecting, storing, analyzing and using information related to the performance and usage of the Product by the End User for any purpose it deems appropriate including but not limited to tracking the End User’s usage of the Product, tracking Product performance, marketing and sales materials, benchmarking studies and further development of the Product. Such information collected shall be construed to be Proprietary Information.
                        </div>
                        <br></br>
                        <div>
                            <b>  7.	</b> End User agrees that at all times End User retain responsibility of all the Data that it uploads, downloads, posts, emails, transmits, stores, shares or otherwise makes available through its use of the Product.
                        </div>
                        <br></br>
                        <div>
                            <b>  8.	</b> End user cannot use this Product without accepting the terms and conditions of this End User License Agreement (EULA). Once you open or Use the Product, it is considered that you accepted all of the terms and conditions of the EULA and you will be licensed to use the Product for your Single License only, solely in connection with Product set out in Annexure 1.
                        </div>
                        <br></br>
                        <div>
                            <b>  9.	</b> This is first version of the Product and Company has full rights of launching new versions of Product and can use its rights to suspend or close the license for the Product at any time with 2 weeks of prior notice.
                        </div>
                        <br></br>
                        <div>
                            <b>  10.	</b> Upon your acceptance of the EULA, it is considered that you agreed to changing the terms and conditions of EULA at any time by Company without your separate acceptance. When such changes are made to EULA, you shall be bound to the updated version.
                        </div>
                        <br></br>
                        <div>
                            <b>  11.	</b> All the rights in the Product are owned by Company solely and any third parties or End User shall not reserve any rights pertaining to the said Product by any which ways.
                        </div>
                        <br></br>
                        <div>
                            <b>  12.	</b> Under all conditions, Company can stop distributing the Product at its sole decision and there shall be no dispute or claims arising pertaining to the losses of any nature towards Company by the end user.
                        </div>
                        <br></br>
                        <div>
                            <b>  13.	</b> No warranty; limitations of liability
                            <br></br>
                            <br></br><b>a.</b>	 Company provides the Product "as is," "with all faults" and "as available." Company does not guarantee the accuracy or timeliness of information deemed to be available from the use of Product. Your use of the Product is at your sole risk. Company gives no express warranties, guarantees or conditions. Company excludes any implied warranties including those of merchantability, fitness for a particular purpose, workmanlike effort, title and non-infringement.
                            <br></br>
                            <br></br><b>b.</b>	Company makes no representations and shall not be liable about the suitability, reliability, availability, timeliness, or viruses or other harmful components (if any) related to the Product.
                            <br></br><b>c.</b>	You understand and agree that Company shall not be liable to you for any direct, indirect, punitive, incidental, special consequential damages or any damages whatsoever including, without limitation damages for loss of use, data, or profits, arising out of or in any way connected with the use or performance of the Product, with delay or inability to use the Product, or the provision or failure to provide the Product, whether based on contract, tort, negligence, strict liability or otherwise, even if Company has been advised of the possibility of damages.
                            <br></br>
                            <br></br><b>d.</b>	Each provision of this clause excluding or limiting liability shall be construed separately, applying and surviving even if for any reason one or other of these provisions is held inapplicable or unenforceable in any circumstances and shall remain in force notwithstanding the expiration or termination of this agreement.
                            <br></br>
                
                        </div>
                        <br></br>
                        <div>
                            <b>  14.	</b> At all times your information provided in conjunction with the use of the Application will be treated in accordance with Privacy Policy of Company which can be viewed at <del style={{color:'red'}}> xxxxxxxxx on the said website</del><ins style={{color:'red'}}>Annexure - I</ins>.
                        </div>
                        <br></br>
                        <div>
                            <b>  15.	</b> California state law governs the interpretation of this Agreement, regardless of conflict of laws principles. Any dispute arising out of or related to this agreement shall exclusively fall within the jurisdiction of courts at San Jose.
                        </div>
                        <br></br>
                        <div>
                            <b>  16.	</b> The license is a paid license with the detailed break-up of cost and duration documented in the attached SOW.
                        </div>
                        <br></br>
                        <div>
                            <b>  17.	</b> Company does not hold any responsibility of loss of data by End User due to natural disaster or disaster due to User's mistake.
                        </div>
                        <br></br>
                         

                         <h3><ins>Annexure 1 – Privacy Policy</ins></h3>
                         
                         <div>
                            <b><ins>OUR PRIVACY POLICY</ins></b>
                            <br></br>
                            <ins>At Tredence, we are committed and obligated to protect your privacy and take reasonable precautions to ensure that any information you provide with us remains secret and private.</ins>
                         </div>
                         <br></br>
                         <div>
                            <b><ins>We respect and value your privacy</ins></b>
                            <br></br>
                            <ins>The information you provide on the website inquiry forms is kept private and confidential.
                            Your contact information (name, phone number, and email address) will only be used by us to communicate with you about our products, services, and solutions. Without your permission, your personal information will never be shared, sold, leased, or provided to any third-party companies for marketing. We use appropriate policies and tools to prevent unauthorized access, use, and/or disclosure of your personal data/information.
                            We may need to share your personal information with approved consultants, affiliates, and business partners to offer our products, services, and solutions.</ins>
                         </div>
                         <br></br>
                         <div>
                            <b><ins>Your information (Cookies) is used to provide you with a better service </ins></b>
                            <br></br>
                            <ins>Our website (www.rebate.ai) uses cookies to provide a personalized and meaningful user experience. We solely use the data gathered by cookies to help us enhance our website’s products, services and solutions. The cookies do not provide us remote access to your systems or access to any personal information you have not shared with us. We will never reveal your location or any other customer-specific information to the public.
                            Cookies are saved and restricted to the memory of your browser or system. You can turn them off in your browser's settings. However, if you disable cookies, you may not be able to use our website to its full potential.</ins>
                         </div>
                         <br></br>
                         <div>
                            <b><ins>Links to external websites</ins></b>
                            <br></br>
                            <ins>Any third-party links on our website are provided only for your ease and convenience and must be used at your risk. Tredence Inc. is not responsible for these third-party websites' content, features, security/privacy policies in any way. </ins>
                            </div>
                         <br></br>
                         <div>
                            <b><ins>GDPR Compliance (For European Union visitors to our website)</ins></b>
                            <br></br>
                            <ins> Basic identity information submitted by you on our websites, such as your name, contact number, email address, company name and country, as well as web/online data such as your IP address, cookies, and system location, is maintained safely and privately. This information will only be used to communicate with current and prospective clients about our products, services, and solutions.
                            Unless a longer retention time is required by law or for closely connected legitimate business objectives, we will not keep this personal information for any longer than is necessary to fulfill the goals outlined in this privacy policy.
                            You have the right to request copies of your personal data and how and where the data is maintained and why it is kept.</ins>
                            </div>
                         <br></br>
                         <div>
                            <b><ins>Changes to our Privacy Policy</ins></b>
                            <br></br>
                            <ins>Tredence Inc. shall review, revise, or update its privacy policy regularly. We'll also make sure that our most current privacy policy is accessible on our website.</ins>
                            <br></br>
                         </div>
                         <div>
                            <br></br>
                            <b><ins>How to get in touch</ins></b>
                            <br></br>
                            <ins>We are GDPR compliant and rigorously adhere to our privacy policies. If you have any queries regarding our privacy policy, please get in touch with us by <b>email at: rebate.ai@tredence.com </b> </ins>
                            <br></br>
                      </div>
                       
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAccept}>Agree</Button>
          <Button onClick={handleDisagree} autoFocus>
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </React.Fragment>
    )
 }